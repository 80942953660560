import React from "react"
import { Link } from 'gatsby'
import styled from 'styled-components'

import Layout from "../components/layout-v2"
import SEO from "../components/seo"

const ThankYouLandingPage = () => {

    return(
        <Layout>
            <SEO 
            title={"We appreciate you contacting White Lion."} 
            description={"One of our team members will contact you shortly to schedule your strategy session with Tim."}
            keywords={"i2i, thank you, White Lion"}
            />
            <MainSection>
                <div>
                    <h1>We appreciate you contacting White Lion.</h1>
                    <p>One of our team members will contact you shortly to schedule your strategy session with Tim.</p>
                    <Link to="/">Return to Home Page</Link>
                </div>
            </MainSection>
        </Layout>
    )
    
}

const MainSection = styled.section`
    max-width: 1360px;
    width: 100%;
    min-height: 80vh;
    margin: 0 auto;
    padding: 0 20px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    > div {
        max-width: 1320px;
        width: 100%;
        padding: 80px 70px;
        margin: 0 auto;
        margin-top: 20px;
        margin-bottom: 60px;
        border: 10px solid #db4140;
        text-align: center;
        h1 {
            font-size: 54px;
            margin-top: 0;
            margin-bottom: 0;
            text-align: center;
        }
        p {
            font-family: "Work Sans";
            font-size: 30px;
            font-weight: 400;
            line-height: 1.3;
            color: #777c7c;
            max-width: 840px;
            margin: 30px auto;
            text-align: center;
        }
        a {
            font-family: "Work Sans";
            font-size: 20px;
            line-height: 1;
            font-weight: 700;
            color: rgb(255, 255, 255);
            text-decoration: none;
            background-color: rgb(219, 65, 64);
            display: inline-block;
            padding: 20px 50px;
            margin: 0px auto;
            margin-bottom: 20px;
            border: none;
            outline: 0;
            &:hover {
                cursor: pointer;
            }
        }
        @media(max-width:1000px) {
            max-width: 700px;
            padding: 45px;
            h1 {
              font-size: 44px;
            }
        }
        @media(max-width: 767px) {
            max-width: 500px;
            padding: 20px;
            border: 5px solid #db4140;
            h1 {
              font-size: 36px;
            }
            p {
              font-size: 18px;
              line-height: 1.4;
            }
            a {
              padding: 10px;
              font-size: 16px;
            }
          }
    } 
`

export default ThankYouLandingPage